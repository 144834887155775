import request from '@/utils/request'

//座位或房主坑位魅力值列表
export function charmList(data){
  return request({
    url:'/web/charm/charmList',
    method:'post',
    data
  })
}

//清空某个坑位的魅力值（注：每次用户下麦、换座位都需要掉此接口）
export function clearPersonCharm(data){
  return request({
    url:'/web/charm/clearPersonCharm',
    method:'post',
    data
  })
}
//清空全部坑位的魅力值（注：每次用户下麦、换座位都需要掉此接口）
export function clearAllCharm(data){
  return request({
    url:'/web/charm/clearCharm',
    method:'post',
    data
  })
}
// PK结束弹框信息
export function endPkInfo(data){
  return request({
    url:'/V2/pk/endPkInfo',
    method:'post',
    data
  })
}

//获取PK贡献值列表【PK结束后展示的列表】
export function getPkReceiveList(data){
  return request({
    url:'/V2/pk/getPkReceiveList',
    method:'post',
    data
  })
}

//PK中下方三个头像的送礼物列表
export function getSendList(data){
  return request({
    url:'/V2/pk/getSendList',
    method:'post',
    data
  })
}

//我的PK记录
export function myPkRecord(data){
  return request({
    url:'/V2/pk/myPkRecord',
    method:'post',
    data
  })
}

//我的PK记录详情
export function pkDetails(data){
  return request({
    url:'/V2/pk/pkDetails',
    method:'post',
    data
  })
}

//PK下方的贡献榜【刷给我的】
export function pkDetailsList(data){
  return request({
    url:'/V2/pk/pkDetailsList',
    method:'post',
    data
  })
}

//PK双方的信息
export function pkUsers(data){
  return request({
    url:'/V2/pk/pkUsers',
    method:'post',
    data
  })
}